import { render, staticRenderFns } from "./applyfrom.vue?vue&type=template&id=5351cbdd&scoped=true&"
import script from "./applyfrom.vue?vue&type=script&lang=js&"
export * from "./applyfrom.vue?vue&type=script&lang=js&"
import style0 from "./applyfrom.vue?vue&type=style&index=0&id=5351cbdd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5351cbdd",
  null
  
)

export default component.exports